<template>
  <top></top>
  <div class="news">
    <div class="title">
      {{ this.NewsDetail.title }}
      <div>2021/12/16</div>
    </div>
    <div class="content">
       {{ this.NewsDetail.content }}
    </div>
  </div>
  <fnav></fnav>
</template>

<script>
import top from "@/components/top.vue";
import fnav from "@/components/fnav.vue";

import { getNewsdetail } from "@/api/news";

export default {
  name: "newsdetail",
  props: {
    newsId: {
      type: Number,
      required: true,
    },
  },
  components: {
    top,
    fnav,
  },
  data() {
    return {
      NewsDetail: {},
    };
  },
  methods: {
    loadNewsDetail: async function () {
      const param = { id: this.newsId };
      const { data } = await getNewsdetail(param);
      this.NewsDetail = data.data;
    },
  },
  created() {
    this.loadNewsDetail();
  },
};
</script>

<style>
</style>