import request from '@/utils/request' //导入基础的请求模块

export const getNewsdetail = (data) => { 
    
    return  request({ 
        method: 'post', 
        url: '/api/newsdetail/', 
        data: data,
        // params: params 
    })

}